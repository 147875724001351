import React from 'react';
import classnames from 'classnames';

import {
  DefaultLayout,
  DefaultLayoutHeader,
  DefaultLayoutContents
} from '../../../../components/templates/DefaultLayout';
import PageHeader from '../../../../components/molecules/CMN/PageHeader/PageHeader';
import * as labels from '../../../../constants/labels';
import helpJson from '../../../../helpSetting.json';

type VerHistoryProps = {
  onClose: () => void;
};

type IconProps = {
  className?: string;
};
const CaretDown: React.FC<IconProps> = props => (
  <span className={classnames(props.className, 'd-inline-block transform-180deg')}>▲</span>
);
const CaretRight: React.FC<IconProps> = props => (
  <span className={classnames(props.className, 'd-inline-block transform-90deg')}>▲</span>
);

const VerHistory: React.FC<VerHistoryProps> = props => {
  const openHelp = (fileName: string, id?: string) => {
    /** HelpページのURL */ // URLが変わる場合、MainHome.tsxのhandleHelpOnClick()も同様に修正する必要あり
    let helpUrl = window.location.origin + helpJson.URL.PageUrl;
    if (0 < fileName.length) {
      helpUrl = helpUrl + helpJson.URL.PageUrlParam + fileName + helpJson.URL.PageUrlExt + (id === undefined ? '' : id);
    }
    // 脆弱性対応向けオプション設定
    const windowFeatures = "noopener,toolbar=yes,location=yes,status=yes,menubar=yes,scrollbars=yes,resizable=yes";
    const winopen = window.open(helpUrl, undefined, windowFeatures);
    if (winopen) {
      // IE,Edge(MSIE版)向け脆弱性対策
      winopen.opener = null;
    }
  };
  return (
    <DefaultLayout className='vw-100 h-100'>
      <DefaultLayoutHeader>
        <PageHeader
          pageTitle={labels.AIKCMN001000001_FUNCTION_NAME_VERSIONHISTORY}
          closeButtonTabIndex={0}
          onCloseClick={props.onClose}
        />
      </DefaultLayoutHeader>
      <DefaultLayoutContents className='p-3 overflow-auto ver-history-contents'>
        <div className='h4 mx-5 text-primary'>{labels.AIKCMN001000001_INPUT_HEADER_VERSIONHISTORY}</div>
        <div className='mx-5 border border-primary ' />
        <div className='mx-5 mt-3'>
        <div className='Contents'>
          <div
            className='divExpansion'
            onClick={() => {
              let obj = document.getElementById('open2_00_0005')!.style;
              obj.display = obj.display == 'none' ? 'block' : 'none';
              let obj2 = document.getElementById('rightV2_00_0005')!.style;
              obj2.display = obj2.display == 'none' ? '' : 'none';
              let obj3 = document.getElementById('downV2_00_0005')!.style;
              obj3.display = obj3.display == 'none' ? '' : 'none';
            }}
          >
            <h4 className='VersionTitle'>
              <span id='rightV2_00_0005' className='VersionTitle-icon' style={{ display: 'none' }}>
                <CaretRight />
              </span>
              <span id='downV2_00_0005' className='VersionTitle-icon'>
                <CaretDown />
              </span>
              バージョン　2.00.0005 (2024/12/15リリース)
            </h4>
          </div>

          <div id='open2_00_0005' style={{ clear: 'both' }}>
            <div className='VersionText'>
              <div className='CommonContent'>
                <span className='dispFont16'>
                  【不具合対応】
                  <ul className='p-0 m-1'>
                    <li>
                      NX-Pro、NX-CEでAI仕訳やAI-OCR入力をご利用中の場合、プロダクトキー変更を実施した後、AI監査支援が起動しなくなることがある点を修正しました。
                    </li>
                  </ul>
                </span>
              </div>
            </div>
          </div>

          <div
              className='divExpansion'
              onClick={() => {
                let obj = document.getElementById('open2_00_0004')!.style;
                obj.display = obj.display == 'none' ? 'block' : 'none';
                let obj2 = document.getElementById('rightV2_00_0004')!.style;
                obj2.display = obj2.display == 'none' ? '' : 'none';
                let obj3 = document.getElementById('downV2_00_0004')!.style;
                obj3.display = obj3.display == 'none' ? '' : 'none';
              }}
            >
              <h4 className='VersionTitle'>
                <span id='rightV2_00_0004' className='VersionTitle-icon'>
                  <CaretRight />
                </span>
                <span id='downV2_00_0004' className='VersionTitle-icon' style={{ display: 'none' }}>
                  <CaretDown />
                </span>
                バージョン　2.00.0004 (2024/7/21リリース)
              </h4>
            </div>

            <div id='open2_00_0004' style={{ clear: 'both', display: 'none' }}>
              <div className='VersionText'>
                <div className='CommonContent'>
                  <span className='dispFont16'>
                    【不具合対応】
                    <ul className='p-0 m-1'>
                      <li>
                        マニュアル・ヘルプの見直しを行い、著増減監査、マイナス残高監査、仕訳明細監査、仕訳重複監査、消費税区分監査の機能説明文を修正しました。
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>

            <div
              className='divExpansion'
              onClick={() => {
                let obj = document.getElementById('open2_00_0003')!.style;
                obj.display = obj.display == 'none' ? 'block' : 'none';
                let obj2 = document.getElementById('rightV2_00_0003')!.style;
                obj2.display = obj2.display == 'none' ? '' : 'none';
                let obj3 = document.getElementById('downV2_00_0003')!.style;
                obj3.display = obj3.display == 'none' ? '' : 'none';
              }}
            >
              <h4 className='VersionTitle'>
                <span id='rightV2_00_0003' className='VersionTitle-icon'>
                  <CaretRight />
                </span>
                <span id='downV2_00_0003' className='VersionTitle-icon' style={{ display: 'none' }}>
                  <CaretDown />
                </span>
                バージョン　2.00.0003 (2024/5/19リリース)
              </h4>
            </div>

            <div id='open2_00_0003' style={{ clear: 'both', display: 'none' }}>
              <div className='VersionText'>
                <div className='CommonContent'>
                  <span className='dispFont16'>
                    【不具合対応】
                    <ul className='p-0 m-1'>
                      <li>
                        GalileoptからAI監査支援の月次残高ルール設定を使用したとき、部門別監査の著増減監査、マイナス残高監査の設定で、部門採用されている科目が正しく表示されないことがある点を修正しました。
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>

            <div
              className='divExpansion'
              onClick={() => {
                let obj = document.getElementById('open2_00_0002')!.style;
                obj.display = obj.display == 'none' ? 'block' : 'none';
                let obj2 = document.getElementById('rightV2_00_0002')!.style;
                obj2.display = obj2.display == 'none' ? '' : 'none';
                let obj3 = document.getElementById('downV2_00_0002')!.style;
                obj3.display = obj3.display == 'none' ? '' : 'none';
              }}
            >
              <h4 className='VersionTitle'>
                <span id='rightV2_00_0002' className='VersionTitle-icon'>
                  <CaretRight />
                </span>
                <span id='downV2_00_0002' className='VersionTitle-icon' style={{ display: 'none' }}>
                  <CaretDown />
                </span>
                バージョン　2.00.0002 (2024/4/7リリース)
              </h4>
            </div>

            <div id='open2_00_0002' style={{ clear: 'both', display: 'none' }}>
              <div className='VersionText'>
                <div className='CommonContent'>
                  <span className='dispFont16'>
                    【不具合対応】
                    <ul className='p-0 m-1'>
                      <li>
                        「AI仕訳チェック」、「監査実行・結果表示」の「仕訳明細監査」「仕訳重複監査」「消費税区分監査」において、使用している補助のコード属性がフリーでかつ、コードに大文字・小文字違いの同一コードが登録されている場合、「ページが表示できない」もしくは「内部サーバーエラー」が発生して処理が実行できなかった点を修正しました。
                        <br></br>※「a」と「A」、「01B」と「01b」が同一と見なされてしまいます。
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>
            <div
              className='divExpansion'
              onClick={() => {
                let obj = document.getElementById('open2_00_0001')!.style;
                obj.display = obj.display == 'none' ? 'block' : 'none';
                let obj2 = document.getElementById('rightV2_00_0001')!.style;
                obj2.display = obj2.display == 'none' ? '' : 'none';
                let obj3 = document.getElementById('downV2_00_0001')!.style;
                obj3.display = obj3.display == 'none' ? '' : 'none';
              }}
            >
              <h4 className='VersionTitle'>
                <span id='rightV2_00_0001' className='VersionTitle-icon'>
                  <CaretRight />
                </span>
                <span id='downV2_00_0001' className='VersionTitle-icon' style={{ display: 'none' }}>
                  <CaretDown />
                </span>
                バージョン　2.00.0001 (2023/7/23リリース)
              </h4>
            </div>

            <div id='open2_00_0001' style={{ clear: 'both', display: 'none' }}>
              <div className='VersionText'>
                <div className='CommonContent'>
                  <span className='dispFont16'>
                    【機能改良】
                    <ul className='p-0 m-1'>
                      <li>
                        「仕訳明細ルール設定一覧」の既存のシステムルールの見直し、インボイス制度対応のシステムルールを追加しました。
                        <br></br>システムルールを元にカスタマイズ作成したユーザールールには変更が反映されませんので、必要に応じて見直しをお願い致します。
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>

            <div
              className='divExpansion'
              onClick={() => {
                let obj = document.getElementById('open2_00_0000')!.style;
                obj.display = obj.display == 'none' ? 'block' : 'none';
                let obj2 = document.getElementById('rightV2_00_0000')!.style;
                obj2.display = obj2.display == 'none' ? '' : 'none';
                let obj3 = document.getElementById('downV2_00_0000')!.style;
                obj3.display = obj3.display == 'none' ? '' : 'none';
              }}
            >
              <h4 className='VersionTitle'>
                <span id='rightV2_00_0000' className='VersionTitle-icon'>
                  <CaretRight />
                </span>
                <span id='downV2_00_0000' className='VersionTitle-icon' style={{ display: 'none' }}>
                  <CaretDown />
                </span>
                バージョン　2.00.0000 (2023/6/11リリース)
              </h4>
            </div>

            <div id='open2_00_0000' style={{ clear: 'both', display: 'none' }}>
              <div className='VersionText'>
                <div className='CommonContent'>
                  <span className='dispFont16'>
                    【機能改良】
                    <ul className='p-0 m-1'>
                      <li>
                        「仕訳明細ルール設定」＞「条件指定」＞「項目」に「事業者区分（0:課税事業者 1:免税事業者等）」を指定できるように追加しました。
                      </li>
                      <li>
                        「監査実行・結果表示」＞「監査結果出力」において、「監査結果【仕訳明細】」タブに事業者区分を追加しました。
                      </li>
                      <li>
                        「データ取込」で使用する仕訳.csvの項目に「事業者区分」を指定できるように追加しました。
                      </li>
                      <li>
                        「データ取込」の仕訳.csvのインポートに「税消費税額自動計算区分」を追加し、内税消費税額を「計算する」、「計算しない」を選択できるようにしました。
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>

            <div
              className='divExpansion'
              onClick={() => {
                let obj = document.getElementById('open1_09_0000')!.style;
                obj.display = obj.display == 'none' ? 'block' : 'none';
                let obj2 = document.getElementById('rightV1_09_0000')!.style;
                obj2.display = obj2.display == 'none' ? '' : 'none';
                let obj3 = document.getElementById('downV1_09_0000')!.style;
                obj3.display = obj3.display == 'none' ? '' : 'none';
              }}
            >
              <h4 className='VersionTitle'>
                <span id='rightV1_09_0000' className='VersionTitle-icon'>
                  <CaretRight />
                </span>
                <span id='downV1_09_0000' className='VersionTitle-icon' style={{ display: 'none' }}>
                  <CaretDown />
                </span>
                バージョン　1.09.0000 (2022/7/31リリース)
              </h4>
            </div>

            <div id='open1_09_0000' style={{ clear: 'both', display: 'none' }}>
              <div className='VersionText'>
                <div className='CommonContent'>
                  <span className='dispFont16'>
                    【機能改良】
                    <ul className='p-0 m-1'>
                      <li>
                        「著増減監査」「マイナス残高」の監査機能において、部門別の残高を監査対象とできるように対応しました。
                      </li>
                      <li>
                        AI監査支援が使用している、開発フレームワーク
                        <br></br>「.NET Core3.1」のサポート期限切れ（2022/12/3）に伴い、
                        <br></br>「.NET6」に対応いたしました。
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>

            <div
              className='divExpansion'
              onClick={() => {
                let obj = document.getElementById('open1_08_0000')!.style;
                obj.display = obj.display == 'none' ? 'block' : 'none';
                let obj2 = document.getElementById('rightV1_08_0000')!.style;
                obj2.display = obj2.display == 'none' ? '' : 'none';
                let obj3 = document.getElementById('downV1_08_0000')!.style;
                obj3.display = obj3.display == 'none' ? '' : 'none';
              }}
            >
              <h4 className='VersionTitle'>
                <span id='rightV1_08_0000' className='VersionTitle-icon'>
                  <CaretRight />
                </span>
                <span id='downV1_08_0000' className='VersionTitle-icon' style={{ display: 'none' }}>
                  <CaretDown />
                </span>
                バージョン　1.08.0000 (2021/12/05リリース)
              </h4>
            </div>

            <div id='open1_08_0000' style={{ clear: 'both', display: 'none' }}>
              <div className='VersionText'>
                <div className='CommonContent'>
                  <span className='dispFont16'>
                    【機能改良】
                    <ul className='p-0 m-1'>
                      <li>
                        仕訳明細ルール設定
                        <li>
                          「保存」ボタンをクリックした際、条件指定でエラーとなった場合の対処方法をわかりやすくするため、エラーメッセージの内容を見直しました。また、エラー個所にフォーカスを移動するように対応しました。
                        </li>
                        <li>
                          「一括償却資産の確認」において、摘要が「ガソリン代」の仕訳を対象外となるように対応しました。
                        </li>
                        <li>
                          仕訳明細ルール「固定資産の購入」で少額のガソリン代などの特定の仕訳を対象から外したい場合の運用方法について、マニュアルに記載しました。
                        </li>
                      </li>
                      <li>
                        月次残高ルール設定　ルールパターンの削除機能を追加しました。
                      </li>
                      <li>
                        監査ルールインポート　インポート時のエラーメッセージについて、エラー箇所がわかるように変更しました。
                        <br></br>（対応前）Excelファイルを選択してください。
                        <br></br>（対応後）拡張子が「xlsx」となっているExcelファイルを選択してください。
                      </li>
                      <li>
                        仕訳明細
                        <li>
                          「売上仕入区分」を追加しました。
                        </li>
                        <li>
                          消費税コード項目において、「軽減税率」「例外税率」を表示色で判別できるように対応しました。
                          <br></br>軽減税率：黄色背景
                          <br></br>例外税率：赤字表示
                        </li>
                      </li>
                      <li>
                        監査実行結果
                        <li>
                          月選択に「決算1」を追加し、選択した場合は「決算1」の金額を集計するように対応しました。
                        </li>
                        <li>
                          月選択で「決算」を選択した場合、「決算1」「決算2」「決算3」の金額を集計するように対応しました。
                        </li>
                        <li>
                          上記2点について、「第一」「中間」「第三」でも同様の対応を行いました。
                        </li>
                        <li>
                          月度バー右に表示していた「※各決算月を集計最終月にして複数月集計した場合、「決算１」が監査対象となっています。」のメッセージを削除しました。
                        </li>
                      </li>
                      <li>
                        データ取込み
                        <br></br>マニュアル・ヘルプの「&#9679; 取り込み必須な項目」のファイル名称「仕訳.txt/.text/.csv」 について、説明を修正しました。
                        <br></br>（修正前）・「金額」または「借方金額」と「貸方金額」
                        <br></br>（修正後）・「金額」
                        <li>
                          マニュアル
                          <br></br>『3.3.1 他社会計システムからデータをエクスポートする』＞「&#9679; 取り込みが必須な項目」
                        </li>
                        <li>
                          ヘルプ
                          <br></br>『MJS AI監査支援』 ＞「他社会計システムからデータを取り込む」＞「監査を実行する」＞「他社会計システムからデータをエクスポートする」＞「&#9679; 取り込みが必須な項目」
                        </li>
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>

            <div
              className='divExpansion'
              onClick={() => {
                let obj = document.getElementById('open1_07_0000')!.style;
                obj.display = obj.display == 'none' ? 'block' : 'none';
                let obj2 = document.getElementById('rightV1_07_0000')!.style;
                obj2.display = obj2.display == 'none' ? '' : 'none';
                let obj3 = document.getElementById('downV1_07_0000')!.style;
                obj3.display = obj3.display == 'none' ? '' : 'none';
              }}
            >
              <h4 className='VersionTitle'>
                <span id='rightV1_07_0000' className='VersionTitle-icon'>
                  <CaretRight />
                </span>
                <span id='downV1_07_0000' className='VersionTitle-icon' style={{ display: 'none' }}>
                  <CaretDown />
                </span>
                バージョン　1.07.0000 (2021/6/27リリース)
              </h4>
            </div>

            <div id='open1_07_0000' style={{ clear: 'both', display: 'none' }}>
              <div className='VersionText'>
                <div className='CommonContent'>
                  <span className='dispFont16'>
                    【機能改良】
                    <ul className='p-0 m-1'>
                      <li>
                        他社会計ソフトで出力したCSVファイル(仕訳ファイル等）をAI監査支援に取込み監査できるように対応しました。
                      </li>
                      <li>
                        監査実行・結果表示画面から仕訳明細画面に遷移時に、ステータス/コメント更新できるように対応しました。また、「仕訳明細監査」「仕訳重複監査」「消費税区分監査」の場合、Excel出力をすると、仕訳毎に監査メッセージが出力されるように対応しました。
                      </li>
                      <li>
                        「仕訳明細重複監査」＞「監査条件」で「科目以外」を対象にした場合、監査実行結果表示画面で監査対象科目を選択すると、仕訳明細表示画面で選択した科目以外の仕訳が重複して2行で表示されるのを、1行に表示されるように改善しました。
                      </li>
                      <li>
                        「仕訳明細重複監査」＞「監査条件」に、「除外科目」を指定できるように対応しました。
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>

            <div
              className='divExpansion'
              onClick={() => {
                let obj = document.getElementById('open1_06_0000')!.style;
                obj.display = obj.display == 'none' ? 'block' : 'none';
                let obj2 = document.getElementById('rightV1_06_0000')!.style;
                obj2.display = obj2.display == 'none' ? '' : 'none';
                let obj3 = document.getElementById('downV1_06_0000')!.style;
                obj3.display = obj3.display == 'none' ? '' : 'none';
              }}
            >
              <h4 className='VersionTitle'>
                <span id='rightV1_06_0000' className='VersionTitle-icon'>
                  <CaretRight />
                </span>
                <span id='downV1_06_0000' className='VersionTitle-icon' style={{ display: 'none' }}>
                  <CaretDown />
                </span>
                バージョン　1.06.0000 (2021/3/21リリース)
              </h4>
            </div>
            <div id='open1_06_0000' style={{ clear: 'both', display: 'none' }}>
              <div className='VersionText'>
                <div className='CommonContent'>
                  <span className='dispFont16'>
                    【機能改良】
                    <ul className='p-0 m-1'>
                      <li>
                        監査結果一覧にて監査報告書を出力できるように対応しました。
                      </li>
                      <li>
                        監査実行・結果表示にて監査結果出力ファイルに「監査結果【仕訳明細_簡易】」シートを出力できるように対応しました。
                      </li>
                      <li>
                        監査実行・結果表示画面から仕訳明細画面に遷移するように対応しました。
                      </li>
                      <li>
                        重複監査の仕訳明細表示を改善しました。
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>

            <div
              className='divExpansion'
              onClick={() => {
                let obj = document.getElementById('open1_05_0000')!.style;
                obj.display = obj.display == 'none' ? 'block' : 'none';
                let obj2 = document.getElementById('rightV1_05_0000')!.style;
                obj2.display = obj2.display == 'none' ? '' : 'none';
                let obj3 = document.getElementById('downV1_05_0000')!.style;
                obj3.display = obj3.display == 'none' ? '' : 'none';
              }}
            >
              <h4 className='VersionTitle'>
                <span id='rightV1_05_0000' className='VersionTitle-icon'>
                  <CaretRight />
                </span>
                <span id='downV1_05_0000' className='VersionTitle-icon' style={{ display: 'none' }}>
                  <CaretDown />
                </span>
                バージョン　1.05.0000 (2020/10/22リリース)
              </h4>
            </div>
            <div id='open1_05_0000' style={{ clear: 'both', display: 'none' }}>
              <div className='VersionText'>
                <div className='CommonContent'>
                  <span className='dispFont16'>
                    【機能改良】
                    <ul className='p-0 m-1'>
                      <li>
                        Galileopt NX-Plus財務大将とMJS AI監査支援の連携に対応しました。
                      </li>
                      <li>
                        MJSLINK NX-Plus財務大将とMJS AI監査支援の連携に対応しました。
                      </li>
                      <li>
                        監査対象月を複数指定できるよう対応しました。
                      </li>
                      <li>
                        5種類の監査を一括で実行できるよう対応しました。
                      </li>
                      <li>
                        仕訳重複監査の条件保存に対応しました。
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>

            <div
              className='divExpansion'
              onClick={() => {
                let obj = document.getElementById('open1_04_0001')!.style;
                obj.display = obj.display == 'none' ? 'block' : 'none';
                let obj2 = document.getElementById('rightV1_04_0001')!.style;
                obj2.display = obj2.display == 'none' ? '' : 'none';
                let obj3 = document.getElementById('downV1_04_0001')!.style;
                obj3.display = obj3.display == 'none' ? '' : 'none';
              }}
            >
              <h4 className='VersionTitle'>
                <span id='rightV1_04_0001' className='VersionTitle-icon'>
                  <CaretRight />
                </span>
                <span id='downV1_04_0001' className='VersionTitle-icon' style={{ display: 'none' }}>
                  <CaretDown />
                </span>
                バージョン　1.04.0001 (2020/8/2リリース)
              </h4>
            </div>
            <div id='open1_04_0001' style={{ clear: 'both', display: 'none' }}>
              <div className='VersionText'>
                <div className='CommonContent'>
                  <span className='dispFont16'>
                    【機能改良】
                    <ul className='p-0 m-1'>
                      <li>
                        ホームにて「障害・メンテナンス情報サイト」ボタンを新設しました。
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>
            <div
              className='divExpansion'
              onClick={() => {
                let obj = document.getElementById('open1_04_0000')!.style;
                obj.display = obj.display == 'none' ? 'block' : 'none';
                let obj2 = document.getElementById('rightV1_04_0000')!.style;
                obj2.display = obj2.display == 'none' ? '' : 'none';
                let obj3 = document.getElementById('downV1_04_0000')!.style;
                obj3.display = obj3.display == 'none' ? '' : 'none';
              }}
            >
              <h4 className='VersionTitle'>
                <span id='rightV1_04_0000' className='VersionTitle-icon'>
                  <CaretRight />
                </span>
                <span id='downV1_04_0000' className='VersionTitle-icon' style={{ display: 'none' }}>
                  <CaretDown />
                </span>
                バージョン　1.04.0000 (2020/7/12リリース)
              </h4>
            </div>
            <div id='open1_04_0000' style={{ clear: 'both', display: 'none' }}>
              <div className='VersionText'>
                <div className='CommonContent'>
                  <span className='dispFont16'>
                    【機能改良】
                    <ul className='p-0 m-1'>
                      <li>
                        中小・小規模企業向け財務パッケージ ACELINK NX-CE会計とMJS AI監査支援の連携に対応しました。
                      </li>
                      【不具合対応】
                      <li>
                        重複項目に「摘要」を設定し、監査対象の仕訳摘要に固定摘要または特殊摘要が同じ位置に入力された仕訳がある場合、異なる固定摘要でも同一と判断してしまった点を修正しました。
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>
            <div
              className='divExpansion'
              onClick={() => {
                let obj = document.getElementById('open1_03_0000')!.style;
                obj.display = obj.display == 'none' ? 'block' : 'none';
                let obj2 = document.getElementById('rightV1_03_0000')!.style;
                obj2.display = obj2.display == 'none' ? '' : 'none';
                let obj3 = document.getElementById('downV1_03_0000')!.style;
                obj3.display = obj3.display == 'none' ? '' : 'none';
              }}
            >
              <h4 className='VersionTitle'>
                <span id='rightV1_03_0000' className='VersionTitle-icon'>
                  <CaretRight />
                </span>
                <span id='downV1_03_0000' className='VersionTitle-icon' style={{ display: 'none' }}>
                  <CaretDown />
                </span>
                バージョン　1.03.0000 (2020/4/19リリース)
              </h4>
            </div>
            <div id='open1_03_0000' style={{ clear: 'both', display: 'none' }}>
              <div className='VersionText'>
                <div className='CommonContent'>
                  <span className='dispFont16'>
                    【機能改良】
                    <ul className='p-0 m-1'>
                      <li>
                        監査結果で入力した監査コメントをExcelへ出力するように改良しました。
                        <a
                          href='javascript:void(0)'
                          className='help-open-link ml-2'
                          onClick={() => openHelp('AIK01110')}
                        >
                          ヘルプ
                        </a>
                      </li>
                      <li>
                        各監査結果の詳細内容を１つのExcelファイルへ出力にできるように改良しました。
                        <a
                          href='javascript:void(0)'
                          className='help-open-link ml-2'
                          onClick={() => openHelp('AIK01088')}
                        >
                          ヘルプ
                        </a>
                      </li>

                      <li>
                        仕訳明細ルール設定一覧のグリッドでフィルタをかけて表示した際、ルールを開いて閉じるとフィルタが解除され、フォーカスが移動していた点をフィルタ情報を保持し、フォーカスを該当のルールに留めるよう対応しました。
                        <a
                          href='javascript:void(0)'
                          className='help-open-link ml-2'
                          onClick={() => openHelp('AIK01069')}
                        >
                          ヘルプ
                        </a>
                      </li>
                      【不具合対応】
                      <li>
                        月次残高ルール設定のグリッドでフィルタをかけて表示した際、保存ボタンの押下でフィルタが解除されていた点をフィルタ情報を保持するように対応しました。
                        <a
                          href='javascript:void(0)'
                          className='help-open-link ml-2'
                          onClick={() => openHelp('AIK01080')}
                        >
                          ヘルプ
                        </a>
                      </li>
                      <li>
                        ダイアログで、画面右上の「×」ボタンでしか閉じることができませんでしたが、「閉じる」ボタンを追加しました。
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>
            <div
              className='divExpansion'
              onClick={() => {
                let obj = document.getElementById('open1_02_0000')!.style;
                obj.display = obj.display == 'none' ? 'block' : 'none';
                let obj2 = document.getElementById('rightV1_02_0000')!.style;
                obj2.display = obj2.display == 'none' ? '' : 'none';
                let obj3 = document.getElementById('downV1_02_0000')!.style;
                obj3.display = obj3.display == 'none' ? '' : 'none';
              }}
            >
              <h4 className='VersionTitle'>
                <span id='rightV1_02_0000' className='VersionTitle-icon'>
                  <CaretRight />
                </span>
                <span id='downV1_02_0000' className='VersionTitle-icon' style={{ display: 'none' }}>
                  <CaretDown />
                </span>
                バージョン　1.02.0000 (2020/3/29リリース)
              </h4>
            </div>
            <div id='open1_02_0000' style={{ clear: 'both', display: 'none' }}>
              <div className='VersionText'>
                <div className='CommonContent'>
                  <span className='dispFont16'>
                    【機能改良】
                    <ul className='p-0 m-1'>
                      <li>
                        監査実行・結果表示にて科目表示の一覧にてソートを行った後、実行結果でステータスを登録してもソートが解除されないように対応しました。
                        <a
                          href='javascript:void(0)'
                          className='help-open-link ml-2'
                          onClick={() => openHelp('AIK01089')}
                        >
                          ヘルプ
                        </a>
                      </li>
                    </ul>
                    <ul className='p-0 m-1'>
                      <li>
                        監査実行・結果表示にてB/S科目、P/L科目別に絞込表示ができるように対応しました。
                        <a
                          href='javascript:void(0)'
                          className='help-open-link ml-2'
                          onClick={() => openHelp('AIK01089')}
                        >
                          ヘルプ
                        </a>
                      </li>
                    </ul>
                    <ul className='p-0 m-1'>
                      <li>
                        仕訳明細ルール設定ダイアログの条件指定で設定していた項目から『条件項目選択ダイアログ』を開いたときに、設定していた項目が選択されている状態で表示するように対応しました。
                        <a
                          href='javascript:void(0)'
                          className='help-open-link ml-2'
                          onClick={() => openHelp('AIK01076', '#AIK01077')}
                        >
                          ヘルプ
                        </a>
                      </li>
                    </ul>
                    <ul className='p-0 m-1'>
                      <li>
                        月次残高ルール設定にて複数パターンを登録できるように対応しました。
                        <a
                          href='javascript:void(0)'
                          className='help-open-link ml-2'
                          onClick={() => openHelp('AIK01080')}
                        >
                          ヘルプ
                        </a>
                        <br />
                        著増減監査・マイナス残高監査で登録したパターンから指定して監査ができるようになります。
                      </li>
                    </ul>
                    <ul className='p-0 m-1'>
                      <li>
                        著増減監査にてしきい値設定に金額を指定できるように対応しました。
                        <a
                          href='javascript:void(0)'
                          className='help-open-link ml-2'
                          onClick={() => openHelp('AIK01080')}
                        >
                          ヘルプ
                        </a>
                      </li>
                    </ul>
                    <ul className='p-0 m-1'>
                      <li>
                        著増減監査にて監査対象に実績を選択できるように対応しました。（借方／貸方発生も選択可能）
                        <a
                          href='javascript:void(0)'
                          className='help-open-link ml-2'
                          onClick={() => openHelp('AIK01080')}
                        >
                          ヘルプ
                        </a>
                      </li>
                    </ul>
                    <ul className='p-0 m-1'>
                      <li>
                        著増減監査にて期首残高と当月を比較対象にできるように対応しました。
                        <a
                          href='javascript:void(0)'
                          className='help-open-link ml-2'
                          onClick={() => openHelp('AIK01080')}
                        >
                          ヘルプ
                        </a>
                      </li>
                    </ul>
                    <ul className='p-0 m-1'>
                      <li>
                        マイナス残高監査で、勘定科目で設定されているマイナス印刷区分と正残区分を考慮し、マイナス残高を判断するように対応しました。
                      </li>
                    </ul>
                    <ul className='p-0 m-1'>
                      <li>
                        システムルール「課税交際費の確認」の条件に「勘定科目（借方・貸方）に交際費を含む」と「摘要にゴルフを含む」を追加しました。
                      </li>
                    </ul>
                    【不具合対応】
                    <ul className='p-0 m-1'>
                      <li>
                        仕訳明細ルール設定で実行対象月選択を個別指定したルールをエクスポートし、インポート対象の顧問先がエクスポートした会計期間と異なる場合、エクスポートした顧問先と異なる月にインポートされた件を修正しました。
                        <a
                          href='javascript:void(0)'
                          className='help-open-link ml-2'
                          onClick={() => openHelp('AIK01083', '#AIK01084')}
                        >
                          ヘルプ
                        </a>
                      </li>
                    </ul>
                    <ul className='p-0 m-1'>
                      <li>
                        システムルールNo.8が「会費の確認」であるところを「会議費の確認」にしていた点について修正し、確認する勘定科目を会議費から会費に変更しました。また、ルールの有効／無効を有効に変更しました。
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>
            <div
              className='divExpansion'
              onClick={() => {
                let obj = document.getElementById('open1_01_0000')!.style;
                obj.display = obj.display == 'none' ? 'block' : 'none';
                let obj2 = document.getElementById('rightV1_01_0000')!.style;
                obj2.display = obj2.display == 'none' ? '' : 'none';
                let obj3 = document.getElementById('downV1_01_0000')!.style;
                obj3.display = obj3.display == 'none' ? '' : 'none';
              }}
            >
              <h4 className='VersionTitle'>
                <span id='rightV1_01_0000' className='VersionTitle-icon'>
                  <CaretRight />
                </span>
                <span id='downV1_01_0000' className='VersionTitle-icon' style={{ display: 'none' }}>
                  <CaretDown />
                </span>
                バージョン　1.01.0000 (2020/2/16リリース)
              </h4>
            </div>
            <div id='open1_01_0000' style={{ clear: 'both', display: 'none' }}>
              <div className='VersionText'>
                <div className='CommonContent'>
                  <span className='dispFont16'>
                    【機能改良】
                    <ul className='p-0 m-1'>
                      <li>
                        仕訳明細ルール、月次残高ルールのバックアップ及び、他顧問先へのコピー機能としてインポート／エクスポート機能を追加対応しました。
                        <a
                          href='javascript:void(0)'
                          className='help-open-link ml-2'
                          onClick={() => openHelp('AIK01082')}
                        >
                          ヘルプ
                        </a>
                      </li>
                      <li>
                        仕訳明細監査で対象となった仕訳の科目（貸借両方）に監査マークを設定していますが、条件に該当した科目（片側）のみ監査マークを設定するように対応しました。
                        <a
                          href='javascript:void(0)'
                          className='help-open-link ml-2'
                          onClick={() => openHelp('AIK01089')}
                        >
                          ヘルプ
                        </a>
                      </li>
                      <li>
                        5種類の監査が全て確認OKになると★が表示され、5種類の監査のうち未実施、または確認OK以外のものがある場合は☆が表示されるように対応しました。
                        <a
                          href='javascript:void(0)'
                          className='help-open-link ml-2'
                          onClick={() => openHelp('AIK01106')}
                        >
                          ヘルプ
                        </a>
                      </li>
                    </ul>
                    【不具合対応】
                    <ul className='p-0 m-1'>
                      <li>
                        部門、共通補助、工事、セグメントコードをチェックする時は、前に０を付けた10桁でチェックしていた点を会計大将で設定したコード属性でチェックするように修正しました。
                        <a
                          href='javascript:void(0)'
                          className='help-open-link ml-2'
                          onClick={() => openHelp('AIK01073')}
                        >
                          ヘルプ
                        </a>
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>
            <div
              className='divExpansion'
              onClick={() => {
                let obj = document.getElementById('open1_00_0002')!.style;
                obj.display = obj.display == 'none' ? 'block' : 'none';
                let obj2 = document.getElementById('rightV1_00_0002')!.style;
                obj2.display = obj2.display == 'none' ? '' : 'none';
                let obj3 = document.getElementById('downV1_00_0002')!.style;
                obj3.display = obj3.display == 'none' ? '' : 'none';
              }}
            >
              <h4 className='VersionTitle'>
                <span id='rightV1_00_0002' className='VersionTitle-icon'>
                  <CaretRight />
                </span>
                <span id='downV1_00_0002' className='VersionTitle-icon' style={{ display: 'none' }}>
                  <CaretDown />
                </span>
                バージョン　1.00.0002 (2019/12/29リリース)
              </h4>
            </div>
            <div id='open1_00_0002' style={{ clear: 'both', display: 'none' }}>
              <div className='VersionText'>
                <div className='CommonContent'>
                  <span className='dispFont16'>
                    【不具合対応】
                    <ul className='p-0 m-1'>
                      <li>
                        消費税科目に取引先マスタを採用しており、その科目の仕訳に対して消費税区分監査を実行すると『内部サーバーエラーが発生しました。』と表示され、消費税区分監査が途中で終了する点を修正しました。
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>
            <div
              className='divExpansion'
              onClick={() => {
                let obj = document.getElementById('open1_00_0001')!.style;
                obj.display = obj.display == 'none' ? 'block' : 'none';
                let obj2 = document.getElementById('rightV1_00_0001')!.style;
                obj2.display = obj2.display == 'none' ? '' : 'none';
                let obj3 = document.getElementById('downV1_00_0001')!.style;
                obj3.display = obj3.display == 'none' ? '' : 'none';
              }}
            >
              <h4 className='VersionTitle'>
                <span id='rightV1_00_0001' className='VersionTitle-icon'>
                  <CaretRight />
                </span>
                <span id='downV1_00_0001' className='VersionTitle-icon' style={{ display: 'none' }}>
                  <CaretDown />
                </span>
                バージョン　1.00.0001 (2019/12/8リリース)
              </h4>
            </div>
            <div id='open1_00_0001' style={{ clear: 'both', display: 'none' }}>
              <div className='VersionText'>
                <div className='CommonContent'>
                  <span className='dispFont16'>
                    【機能改良】
                    <ul className='p-0 m-1'>
                      <li>
                        ＡＩ仕訳の学習を月別に実施していた点を、送信したデータの全月を対象に学習するように変更しました。
                        <br />
                        ・仕訳ＡＩチェック
                        <a
                          href='javascript:void(0)'
                          className='help-open-link ml-2'
                          onClick={() => openHelp('AIK01108')}
                        >
                          ヘルプ
                        </a>
                      </li>
                      <li>
                        システムの仕訳明細ルールNo.8、16、17において、標準の採用をOFFとしました。
                        <br />
                        ・仕訳明細ルール設定一覧
                        <a
                          href='javascript:void(0)'
                          className='help-open-link ml-2'
                          onClick={() => openHelp('AIK01069')}
                        >
                          ヘルプ
                        </a>
                      </li>
                    </ul>
                    【不具合対応】
                    <ul className='p-0 m-1'>
                      <li>
                        NX-Pro会計大将の下記のデータの場合、データ送信時に『顧問先データ取込に失敗したため、データ送信を中断します』と表示され、データ送信に失敗する点を修正しました。
                        <br />
                        ・OCR摘要イメージが含まれているデータ
                        <br />
                        ・消費税10％の課税期間の消費税申告書が作られているデータ
                      </li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>
            <div
              className='divExpansion'
              onClick={() => {
                let obj = document.getElementById('open1_00_0000')!.style;
                obj.display = obj.display == 'none' ? 'block' : 'none';
                let obj2 = document.getElementById('rightV1_00_0000')!.style;
                obj2.display = obj2.display == 'none' ? '' : 'none';
                let obj3 = document.getElementById('downV1_00_0000')!.style;
                obj3.display = obj3.display == 'none' ? '' : 'none';
              }}
            >
              <h4 className='VersionTitle'>
                <span id='rightV1_00_0000' className='VersionTitle-icon'>
                  <CaretRight />
                </span>
                <span id='downV1_00_0000' className='VersionTitle-icon' style={{ display: 'none' }}>
                  <CaretDown />
                </span>
                バージョン　1.00.0000 (2019/11/21リリース)
              </h4>
            </div>
            <div id='open1_00_0000' style={{ clear: 'both', display: 'none' }}>
              <div className='VersionText'>
                <div className='CommonContent'>
                  <span className='dispFont16'>
                    MJS AI監査支援をリリースしました。
                    <a href='javascript:void(0)' className='help-open-link' onClick={() => openHelp('AIK00000')}>
                      ヘルプ
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DefaultLayoutContents>
    </DefaultLayout>
  );
};

export default VerHistory;
